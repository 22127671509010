import React, { useState } from 'react';
import { Link } from 'gatsby'

import { useFirebase } from 'components/firebase/Firebase';
import Terms from 'components/termsmodal/Terms.js'

import ROUTES from "utils/routes";

import universities from './universities.json'

const SignUpForm = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [passwordOne, setPasswordOne] = useState("")
  const [passwordTwo, setPasswordTwo] = useState("")
  const [organization, setOrganization] = useState("")

  const firebase = useFirebase()
  
  const invalid = passwordOne!==passwordTwo || passwordOne==='' || email.trim()==='' || organization.trim()===''

  const onSubmit = event => {
    event.preventDefault();

    if(!invalid) {
      firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(
        authUser => firebase.user(authUser.user.uid).set({ email }) // Create a user in your Firebase realtime database
      ).then(() => {
        setEmail("")
        setError(null)
        setPasswordOne("")
        setPasswordTwo("")
        setOrganization("")
        //you do not have to manually redirect here, the LoggedOutRoute component handles this for you
      })
      .catch(setError);
    }
  }

  return (
    <form onSubmit={e => e.preventDefault()}>
      <label>Email *</label>
      <input
        className="form-control"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
        aria-required="true"
      />
      <label>Password *</label>
      <input
        className="form-control"
        name="passwordOne"
        value={passwordOne}
        onChange={e => setPasswordOne(e.target.value)}
        type="password"
        aria-required="true"
      />
      <label>Confirm Password *</label>
      <input
        className="form-control"
        name="passwordTwo"
        value={passwordTwo}
        onChange={e => setPasswordTwo(e.target.value)}
        type="password"
        aria-required="true"
        id={passwordOne===passwordTwo ? 'validField' : 'invalidField'}
      />
      <label>Organization *</label>
      <select
        className="form-control"
        name="organization"
        onChange={e => setOrganization(e.target.value)}
      >
        {industries.map((ind, idx) =>
          <option key={idx} value={ind}>{ind}</option>)
        }
      </select>

      <AdditionalForm org={organization} />


      <div className="centering">
        <Terms message="Sign Up" isInvalid={invalid} onSubmit={onSubmit}/>
      </div>


      {error && <p>{error.message}</p>}
    </form>
  );
}

export default SignUpForm

function AdditionalForm(props) {
  if (props.org==='Higher Education') {
    return(
      <div>
        <form>
          <label>Institution</label>
          <select
            className="form-control"
            name="institution"
          >
          {universities.map((ins, i) =>
            <option value={ins}>{ins}</option>)
          }
          </select>
          <label>Role</label>
          <select
            className="form-control"
            name="role"
          >
          {roles.map((r, i) =>
            <option value={r}>{r}</option>)
          }
          </select>

        </form>
      </div>
    )
  }
  else if (props.org==='Other'){
    return(
      <div>
        <label>Other Organization</label>
        <input className="form-control" />
      </div>
    )
  }
  
  return null
}


export const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);




const roles = [
  '',
  'Undergraduate student',
  'Graduate student',
  'Postdoc',
  'Associate Professor',
  'Professor',
]

const industries = [
  '',
  'Government',
  'K-12 Education',
  'Higher Education',
  'Business, finance, and marketing',
  'Profession or trade organization',
  'Media and journalism',
  'Religious organization',
  'Think tank',
  'Advocacy',
  'Political party',
  'Lobbying firm',
  'Other',
]
