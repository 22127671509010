import React from 'react'
import LoggedOutRoute from "../components/loggedOutRoute/LoggedOutRoute"
import SignIn from "components/firebase/SignIn"

const SignInPage = (props) => {
  return (
    <LoggedOutRoute {...props} component={SignIn}/>
  )
}
export default SignInPage
